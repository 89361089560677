import dynamic from 'next/dynamic';
import { Box } from '@chakra-ui/react';
import {
  AcfServiceTabs,
  Field,
  SelectedEntriesField,
  PostContentAccordionItemProps,
  ServiceTestimonialsProps,
  TeaserMembershipProps,
  FeaturesBlockProps,
  ElectionsCtaProps,
  ElectionResultsProps,
  HeroProps,
  YoutubeVideEmbedProps,
  VelloEmbedProps,
  IntegrationBlockProps,
  KeyFiguresProps,
  ForestExpertProps,
  ServiceCategoryTeasersProps,
  EventTeasers as EventTeasersProps,
  AccordionBlockItemProps,
  ServiceOrderRequestProps,
  Newsletter as NewsletterProps,
  ContactBlock as ContactBlockProps,
  MembershipBlock as MembershipBlockProps,
  FormTabs as FormTabsProps,
  MembershipFee as MembershipFeeProps,
  BoardMembers as BoardMembersProps,
  NewsTeasers as NewsTeasersProps,
  OfficeTeasers as OfficeTeasersProps,
  ForestAccountCalculator as ForestAccountCalculatorProps,
  ServiceFormCalendar as ServiceFormCalendarProps,
  ElectionsAccordionProps,
} from '../../types';
import { ButtonsProps } from '../Buttons';

const HtmlContent = dynamic(() => import('../HtmlContent'));
const HtmlButtons = dynamic(() => import('./HtmlButtons'));
const Heading = dynamic(() => import('../Heading'));
const Buttons = dynamic(() => import('../Buttons'));
const SelectedEntries = dynamic(() => import('../SelectedEntries'));
const ServiceTabs = dynamic(() => import('../Services/ServiceTabs'));
const AccordionBlock = dynamic(() => import('../AccordionBlock'));
const ServiceOrderRequest = dynamic(
  () => import('../Services/ServiceOrderRequest'),
);
const PostContentAccordion = dynamic(() => import('../PostContentAccordion'));
const TeaserHero = dynamic(() => import('../TeaserHero'));
const TeaserMembership = dynamic(() => import('../TeaserMembership'));
const FeaturesBlock = dynamic(() => import('../FeaturesBlock'));
const ElectionsCta = dynamic(() => import('./ElectionsCta'));
const ElectionResults = dynamic(() => import('./ElectionResults'));
const Testimonials = dynamic(() => import('../Testimonials'));
const KeyFigures = dynamic(() => import('./KeyFigures'));
const ForestExpert = dynamic(() => import('./ForestExpert'), { ssr: false });
const EventTeasers = dynamic(() => import('./EventTeasers'));
const ServiceCategoryTeasers = dynamic(
  () => import('../ServiceCategoryTeasers'),
);
const Hero = dynamic(() => import('../TeaserHeroFull'));
const Newsletter = dynamic(() => import('./Newsletter'));
const NewsTeasers = dynamic(() => import('./NewsTeasers'));
const ContactBlock = dynamic(() => import('./ContactBlock'));
const MembershipBlock = dynamic(() => import('./MembershipBlock'));
const FormTabs = dynamic(() => import('./FormTabs'));
const MembershipFee = dynamic(() => import('./MembershipFee'));
const BoardMembers = dynamic(() => import('./BoardMembers'));
const OfficeTeasers = dynamic(() => import('./OfficeTeasers'));
const YoutubeVideoEmbed = dynamic(() => import('../YoutubeVideoEmbed'));
const VelloEmbed = dynamic(() => import('../VelloEmbed'));
const IntegrationBlock = dynamic(() => import('../IntegrationBlock'));
const ForestAccountCalculator = dynamic(
  () => import('./ForestAccountCalculator'),
);
const ServiceFormCalendar = dynamic(() => import('./ServiceFormCalendar'), {
  ssr: false,
});

interface Props {
  fields: Field[];
  fieldsType?: 'section' | 'basic';
}

const hasHero = (fields?: Field[]) => {
  const [maybeHero] = fields || [];
  return maybeHero?.__name?.startsWith('acf/hero') && maybeHero?.data;
};

export const resolveFields = (fields: Field[]): [null | HeroProps, Field[]] => {
  if (hasHero(fields)) {
    const [hero, ...contentFields] = fields;
    return [hero.data as HeroProps, contentFields];
  }
  return [null, fields];
};

export const hasForestExpertField = (fields: Field[]): boolean =>
  !!fields.find(({ __name }) => __name === 'acf/forest-expert');

const Fields = ({ fields, fieldsType = 'section' }: Props) => (
  <>
    {fields?.filter(Boolean).map(({ __name, html = '', data }, i) => {
      switch (__name) {
        case 'acf/video':
          return (
            <Box marginBottom={5} data-test-id="section-youtube-embed">
              <YoutubeVideoEmbed
                {...(data as YoutubeVideEmbedProps)}
                key={`${__name}-${i}`}
              />
            </Box>
          );
        case 'acf/vello-embed':
          return (
            <Box
              marginBottom={5}
              data-test-id="section-vello-embed"
              key={`${__name}-${i}`}
            >
              <VelloEmbed {...(data as VelloEmbedProps)} />
            </Box>
          );
        case 'acf/integration-block':
          return (
            <IntegrationBlock
              data-test-id="section-integration-block"
              {...(data as IntegrationBlockProps)}
              key={`${__name}-${i}`}
            />
          );
        case 'acf/selected-entries':
          return (
            <SelectedEntries
              data-test-id="section-selected-entries"
              {...(data as SelectedEntriesField)}
              key={`${__name}-${i}`}
            />
          );
        case 'acf/latest-articles':
          return (
            <SelectedEntries
              data-test-id="section-latest-articles"
              {...(data as SelectedEntriesField)}
              key={`${__name}-${i}`}
            />
          );
        case 'acf/teaser-large':
          return (
            <TeaserHero
              {...(data as HeroProps)}
              key={`${__name}-${i}`}
              data-test-id="section-teaser-hero"
            />
          );
        case 'acf/teaser-membership':
          return (
            data && (
              <TeaserMembership
                data-test-id="section-teaser-membership"
                {...(data as TeaserMembershipProps)}
                key={`${__name}-${i}`}
              />
            )
          );
        case 'acf/features-block':
          return (
            data && (
              <FeaturesBlock
                data-test-id="section-features-block"
                {...(data as FeaturesBlockProps)}
                key={`${__name}-${i}`}
              />
            )
          );
        case 'acf/elections-cta':
          return (
            data && (
              <ElectionsCta
                data-test-id="section-elections-cta"
                {...(data as ElectionsCtaProps)}
                key={`${__name}-${i}`}
              />
            )
          );
        case 'acf/election-results':
          return (
            data && (
              <ElectionResults
                data-test-id="section-election-results"
                {...(data as ElectionResultsProps)}
                key={`${__name}-${i}`}
              />
            )
          );
        case 'core/paragraph':
          return (
            <HtmlContent
              html={html}
              key={`${__name}-${i}`}
              data-test-id="section-core-paragraph"
            />
          );
        case 'core/buttons':
          return (
            <HtmlButtons
              html={html}
              key={`${__name}-${i}`}
              data-test-id="section-core-buttons"
            />
          );
        case 'acf/buttons':
          return (
            <Buttons
              data-test-id="section-buttons"
              buttons={data as ButtonsProps['buttons']}
              _notLast={{ mb: 5 }}
              pt="1"
              size="large"
              key={`${__name}-${i}`}
            />
          );
        case 'acf/service-detail-tabs':
          return (
            <ServiceTabs
              data-test-id="section-service-tabs"
              data={data as AcfServiceTabs[]}
              key={`${__name}-${i}`}
            />
          );
        case 'acf/testimonials':
          return (
            <Testimonials
              data-test-id="section-testimonials"
              {...(data as ServiceTestimonialsProps)}
              key={`${__name}-${i}`}
            />
          );
        case 'acf/accordion-block':
          return (
            <AccordionBlock
              data-test-id="section-accordion-block"
              data={data as AccordionBlockItemProps[]}
              key={`${__name}-${i}`}
              accordionType={fieldsType}
            />
          );
        case 'mhy/post-content-accordion':
          return (
            <PostContentAccordion
              data-test-id="section-page-accordion"
              data={data as PostContentAccordionItemProps[]}
              key={`${__name}-${i}`}
            />
          );
        case 'acf/elections-accordion': {
          const { entries, heading } = data as ElectionsAccordionProps;
          return (
            <Box data-test-id="section-page-accordion" key={`${__name}-${i}`}>
              {heading && (
                <Heading
                  as="h2"
                  variant="h5"
                  mb={6}
                  data-test-id="section-elections-accordion-heading"
                >
                  {heading.title}
                </Heading>
              )}
              {entries && (
                <PostContentAccordion
                  data={entries as PostContentAccordionItemProps[]}
                />
              )}
            </Box>
          );
        }
        case 'mhy/service-order-request':
          return (
            <ServiceOrderRequest
              data-test-id="section-service-order-request"
              {...(data as ServiceOrderRequestProps)}
              key={`${__name}-${i}`}
            />
          );
        case 'acf/service-category-teasers':
          return (
            <ServiceCategoryTeasers
              data-test-id="section-service-category-teasers"
              {...(data as ServiceCategoryTeasersProps)}
              key={`${__name}-${i}`}
            />
          );
        case 'acf/key-figures':
          return (
            <KeyFigures
              {...(data as KeyFiguresProps)}
              key={`${__name}-${i}`}
              data-test-id="section-key-figures"
            />
          );
        case 'acf/forest-expert':
          return (
            <Box
              id="forest-expert"
              key={`${__name}-${i}`}
              data-test-id="section-forest-expert"
            >
              <ForestExpert {...(data as ForestExpertProps)} />
            </Box>
          );
        case 'acf/events-teaser':
          return (
            <EventTeasers
              data-test-id="section-event-teaser"
              {...(data as EventTeasersProps)}
              key={`${__name}-${i}`}
            />
          );
        case 'acf/hero': {
          return (
            <Hero
              {...(data as HeroProps)}
              key={`${__name}-${i}`}
              data-test-id="section-hero"
            />
          );
        }
        case 'mhy/newsletter':
          return (
            <Newsletter
              {...(data as NewsletterProps)}
              key={`${__name}-${i}`}
              data-test-id="section-newsletter"
            />
          );
        case 'acf/contact-block': {
          return (
            <ContactBlock
              data-test-id="section-content-block"
              {...(data as ContactBlockProps)}
              key={`${__name}-${i}`}
            />
          );
        }
        case 'acf/membership-block': {
          return (
            <MembershipBlock
              data-test-id="section-membership-block"
              {...(data as MembershipBlockProps)}
              key={`${__name}-${i}`}
            />
          );
        }
        case 'acf/form-tabs':
          return (
            <FormTabs
              tabs={data as FormTabsProps}
              key={`${__name}-${i}`}
              data-test-id="section-form-tabs"
            />
          );
        case 'acf/membership-fee':
          return (
            <MembershipFee
              data-test-id="section-membership-fee"
              {...(data as MembershipFeeProps)}
              key={`${__name}-${i}`}
            />
          );
        case 'acf/board-members':
          return (
            <BoardMembers
              data-test-id="section-board-members"
              {...(data as BoardMembersProps)}
              key={`${__name}-${i}`}
            />
          );
        case 'acf/news-teaser':
          return (
            <NewsTeasers
              data-test-id="section-news-teasers"
              {...(data as NewsTeasersProps)}
              key={`${__name}-${i}`}
            />
          );
        case 'acf/office-teaser': {
          return (
            <OfficeTeasers
              data-test-id="section-office-teasers"
              {...(data as OfficeTeasersProps)}
              key={`${__name}-${i}`}
            />
          );
        }
        case 'acf/forest-account-calculator': {
          return (
            <ForestAccountCalculator
              data-test-id="section-forest-account-calculator"
              {...(data as ForestAccountCalculatorProps)}
              key={`${__name}-${i}`}
            />
          );
        }
        case 'acf/serviceform-calendar': {
          return (
            <ServiceFormCalendar
              data-test-id="section-serviceform-calendar"
              {...(data as ServiceFormCalendarProps)}
              key={`${__name}-${i}`}
            />
          );
        }
        default: {
          if (__name?.includes('acf/') || __name?.includes('mhy/')) {
            console.warn('Missing field resolver for: ', __name);
          }
          return (
            <HtmlContent
              data-test-id="section-unresolved-html-content"
              html={html}
              key={`${__name}-${i}`}
            />
          );
        }
      }
    })}
  </>
);

export default Fields;
